<template>
  <div>
    <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
    <b-row v-if="isContentLoaded">
      <b-col md="12">
        <apex-line-chart :data-object="reportData.billCountByYears"></apex-line-chart>
      </b-col>
      <b-col md="6" v-if="reportData.billCountByServiceGroup.xaxis.categories.length > 0">
        <apex-bar-chart :data-object="reportData.billCountByServiceGroup"></apex-bar-chart>
      </b-col>
      <b-col md="6" v-if="reportData.billCountByService.xaxis.categories.length > 0">
        <apex-bar-chart :data-object="reportData.billCountByService"></apex-bar-chart>
      </b-col>
      <b-col md="6" v-if="reportData.billCountByProductType.xaxis.categories.length > 0">
        <apex-bar-chart :data-object="reportData.billCountByProductType"></apex-bar-chart>
      </b-col>
      <b-col md="6" v-if="reportData.billCountByProduct.xaxis.categories.length > 0">
      <apex-bar-chart :data-object="reportData.billCountByProduct"></apex-bar-chart>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import reportService from '../../../services/report';

  import Loader from '../../../components/shared/Loader';
  import apexBarChart from '../../../components/apexCharts/ApexBarChart';
  import apexLineChart from '../../../components/apexCharts/ApexLineChart';

  export default {
    name: 'SaleTrend',
    props: {
      getPayload: String
    },
    components: {
      Loader,
      apexBarChart,
      apexLineChart
    },
    data() {
      return {
        reportData: {},
        pageName: 'bill',
        isContentLoaded: false,
      }
    },
    methods: {
      getData(url) {
        this.reportData = {};
        reportService.getReportData(url, this.pageName)
          .then(response => {
            if (response) {
              this.reportData = response;
            }
            else {
              this.$toastr.error(this.$t("error"));
            }
          }).finally(() => this.isContentLoaded = true)
      },
    },
    computed: {
      url: function () {
        return this.getPayload;
      },
    },
    watch: {
      url: {
        handler: function (val) {
          this.isContentLoaded = false
          this.getData(val);
        },
        immediate: true
      }
    },
    mounted: function () {
    }
  }
</script>
